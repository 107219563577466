
.board{
    /* 부모 div에 flex속성을 주고 그 안의 div를 가로정렬(row) */
    display: flex;
    flex-direction: row;
    width: 100%;

    /* 7/25일 : 왠지모르겠지만 board라는 이름의 css는 다른 css파일에서 사용할때 뭔가가 공유되는듯..*/
    /* 프로젝트페이지랑 연혁 활동일자에 board 추가해줬는데 뭔가가 이상했다 -> his_, pro_ 등으로 css이름을 바꿔주니까 기존처럼 돌아옴 */
    height: 100vh;
    /* background-color: #F3EED9; */

}



.textDiv{
    display: block;
    padding-top: 3%;
    padding-left: 6%;  
    width: 60%;
}

.mainText{
    font-size: 8vw;
    font-family: 'Tektur', cursive;
    color:#57423F;
    display: block; 
    animation-name: fadeIn;
    animation-duration: 2s;
}

.initialText{
    color: #B43131;
}

.teamText{
    display: block;
    /* width: 29.5%; */
    font-family: 'Noto Sans KR', sans-serif;
    margin-top: 40vh;
    line-height: 1.5em;
    font-size: 2vw;
    color:#57423F;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
    opacity:0;
}
    

.ani{
    padding-top: 100px;
    height : 400px;
    width: 500px;

}    




/* css 애니메이션 넣기 */
@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
  }



/* 반응형...은 일단 나중에 */
@media (max-width: 700px) {
    .board{
        /* 부모 div에 flex속성을 주고 그 안의 div를 가로정렬(row) */
        display: flex;
        flex-direction: column;
        width: 100%;
    
        /* 7/25일 : 왠지모르겠지만 board라는 이름의 css는 다른 css파일에서 사용할때 뭔가가 공유되는듯..*/
        /* 프로젝트페이지랑 연혁 활동일자에 board 추가해줬는데 뭔가가 이상했다 -> his_, pro_ 등으로 css이름을 바꿔주니까 기존처럼 돌아옴 */
        height: 100vh;
        /* background-color: #F3EED9; */
    
    }

    .textDiv{
        display: block;
        margin-top: 3vh;
        margin-left: 0%;  
        /* width: 60%; */
    }

    .mainText {
    font-size: 18vw;
      color: #57423F;
    }

    .teamText{
        display: block;
        font-size: 6vw;
        font-family: 'Noto Sans KR', sans-serif;
        color:#57423F;

        margin-top: 3vh;

        text-align: center;

    }

    
  }