
.btn{
    position: absolute;
    height: 80px;
    width: 80px;
    border-radius: 100px;
    border-style: solid;
    border-color: #57423F;
    border-width: 11px;

    background-color: #F3EED9;

    /* background-image: url("images/Arrow.png"); */


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    top: 85vh;
    z-index: 1;


    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    opacity:0;
}

.mainBackground{
    /*background: linear-gradient(180deg, white,#FFE5DD);*/
    background: linear-gradient(180deg, white,#F3EED9);
    height: 100vh;
}

.btn:hover{
    box-shadow: 0px 0px 15px #382a28;
    transition: 0.5s;
}


.test{
    height: 2000px;
}



/* css 애니메이션 넣기 */
@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
  }



  /* 반응형...은 일단 나중에 */
@media (max-width: 700px) {
    
.btn{
    position: absolute;
    height: 55px;
    width: 55px;
    border-radius: 100px;
    border-style: solid;
    border-color: #57423F;
    border-width: 8px;

    background-color: #F3EED9;

    /* background-image: url("images/Arrow.png"); */


    /* 폰마다 버튼 위치가 이상하게 보이긴 함... */
    margin-top: 41vh;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* top: 870px; */
    z-index: 1;


    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    opacity:0;
}

.btn_image{
    width: 32px;
    height: 30px;
    padding-top: 3px;
}


  }
