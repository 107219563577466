.FAQground{
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-bottom: 8rem;
    margin-top: 5rem;;
}

.Faq{
    font-family:'Tektur', cursive;
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.4rem;
    color:black;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
    outline: #B43131;
    /*outline-style: solid;*/
}
.title_item{
    text-align: center ;
    justify-content: center;
    margin:5px auto 5px auto;

    width: 80vw;
    padding: 20px;
    border-radius: 1rem;
    background-color: #B43131;

    font-family: 'Noto Sans KR', sans-serif;
    font-size: 30px ;
    color: #F3EED9;
}
.title_item_long{
    text-align: center ;
    justify-content: center;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 90vw;
    padding: 20px;
    border-radius: 1rem;
    background-color: #B43131;

    font-family: 'Noto Sans KR', sans-serif;
    font-size: 25px ;
    color: #F3EED9;
}

.answer_item {
    background-color: #F3EED9;
    text-align: center;
    justify-content: center;
    width: 80vw;
    height: 30%;
    margin: auto;
    /* margin-bottom: 5%; */
    margin-bottom: 1rem;
    padding: 30px 40px;
    border-radius: 0.7rem;

    font-family: 'Noto Sans KR', sans-serif;
    font-size: 20px;
    color: #57423F;
    line-height: 150%;
}
.kuhasNotion {
    color: black;
    text-decoration: none;
    cursor: pointer;
    background: linear-gradient(to right, crimson, #FF8C00);
    padding: 2px;
    border-radius: 5px;
    border: transparent;
    transition: padding 0.3s ease, box-shadow 0.3s ease;
}
.kuhasNotion:hover {
    padding: 5px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 6px 3px 3px grey;
}

.vacationBold{
    text-decoration: underline;
    font-weight: 600;
}


@media (max-width: 700px) {
    .FAQground {
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        margin-bottom: 8rem;
    }
    .title_item {
        text-align: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 80vw;
        padding: 20px;
        border-radius: 1rem;
        background-color: #B43131;

        font-family: 'Noto Sans KR', sans-serif;
        font-size: 5.5vw;
        color: #F3EED9;
    }
    .answer_item {
        background-color: #F3EED9;
        text-align: center;
        justify-content: center;
        width: 70vw;
        height: 30%;
        margin: auto;
        margin-bottom: 5%;
        padding: 20px 30px;
        border-radius: 0.7rem;

        font-family: 'Noto Sans KR', sans-serif;
        font-size: 5vw;
        color: #57423F;
        line-height: 150%;
    }
    .title_item_long {
        text-align: center;
        justify-content: center;
        margin: auto;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 90vw;
        padding: 15px;
        border-radius: 1rem;
        background-color: #B43131;

        font-family: 'Noto Sans KR', sans-serif;
        font-size: 5vw;
        color: #F3EED9;
        line-height: 140%;
    }

}