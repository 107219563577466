.nav{
  float: left;
  height: 70px;
  width: 100%;
  background-color: #B43131;
  text-align: center;

  position: sticky;
  top: 0;
  z-index: 10;

  display: flex;
  flex-direction: row;
}


.link {
  display: block;
  height: 25px;
  line-height: 70px;
  color:#F3EED9;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;

  font-family: 'Noto Sans KR', sans-serif;
  font-size: 22px;
}

.logo {
  margin-left: 1%;
  width:120px;
  height:70px;
}

.gap {
  width : 60%;
}

.areaLink{
  width: 10%; 
}


/* 모바일에서만 보일 햄버거메뉴 */
.hamburger{
  display: none;
}


/* 반응형...은 일단 나중에 */
@media (max-width: 700px) {
.mainText {
  display: none;
}

.nav{
  float: left;
  height: 10vh;
  width: 100%;
  background-color: #B43131;
  text-align: center;

  position: sticky;
  top: 0;
  z-index: 10;

  display: flex;
  flex-direction: row;

  justify-content: center;
}

.areaLink{
  display: none;
}

.gap{
  display: none;
}

.logo{
  margin-left: 0;
  width: 30vw;
  height: 10vh;
}

/* 햄버거 메뉴 생기면 여기다가 */
.hamburger{

}
}

