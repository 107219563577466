    *{ padding: 0; margin: 0; }


    .modal{
            position:absolute;
            display: flex;
            justify-content: center;

            width:300px;
            height:500px;
            align-items: center;
            background-color: yellow;
    }

    .Title{
      font-family: 'Tektur', cursive;
      font-weight: 600;
      font-size: 48px;
      text-align: center;
      padding-bottom: 20px;
    }
    .Portfolio_ItemWrapper{
      font-family: 'Noto Sans KR', sans-serif;
      margin: 30px auto;
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      border: solid white;
      justify-content: space-between;

    }

    .Portfolio_Item{
        width: calc(33.3333% - 8px);;
        height: 399px;
        margin-bottom: 50px;
        /*margin: 8px;*/
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
    }

    .Portfolio_Item:last-child{ margin-right: 0; }

    .Portfolio_Item a{
      display: block;
      /*width: 333px; height: 399px;*/
      position: relative;
      border: 2px solid white;
    }

    .figure-container {
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*width: 333px;*/
        height:100%;
    }

    .figure-container img {
        height: 399px;
        width: 300px;
        max-width: 100%;
        height: auto;
        margin: 20px;
        /*border: 5px solid black;*/
        /*box-shadow: 5px 5px 10px rgba(0,0,0,0.9);*/
        border-radius: 25px;
        opacity: 1;
        transition: opacity 0.75s;
        z-index: 1;
    }

    .figure-container figcaption {
      width: 100%; height:100%;
      /*max-width: 333px;*/
      position: absolute;
      /*background-color: rgba(0,0,0,0.2);*/
      background-color: rgba(240,234,208);
      text-align: center;
      display:block;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      padding: 35px;
    }

    .figure-container  .part1{
        font-family: 'Noto Sans KR', sans-serif;
        color: rgba(240,234,208);
        /* line-height: 1.5; */
        line-height: 1.8;
        opacity: 0.85;
        width: 100%;
        font-size: 1.5rem;
        font-weight: 700;
        background-color: rgb(163, 31, 37);
        text-align: center;
        z-index: 1;
        /* border-radius: 10px; */
        border-radius: 25px 25px 0px 0px;
    }

    .figure-container figcaption .part2{
        color: rgb(87, 66, 63);
        /*background-color: rgba(0,0,0,0.5);*/
        line-height: 1.5;
        opacity: 0.01;
        transition: 0.75s;
        margin: auto;
    }

    .Portfolio_Item a:hover figcaption .part2{
      opacity: 0.9;
    }

    .Portfolio_Item a:hover figure img{
      opacity: 0.01;
      transition: opacity 0.75s;
    }

    @media screen and (min-width: 1701px) {
    .Portfolio_Item {
        width: calc(33.3333% - 8px);
    }
    }


    @media screen and (min-width: 1127px) and (max-width: 1700px) {
     .Portfolio_Item {
      width: calc(50% - 8px);
    }
    }

    @media screen and (max-width: 1126px) {
    .Portfolio_Item {
     width: 100%;
     }
    }



    /* 모바일 반응형 뷰 만지기 */
    @media (max-width: 700px) {

      .Title{
        font-family: 'Tektur', cursive;
        font-weight: 600;
        font-size: 12vw;
        /* text-align: center; */
      }
      .Portfolio_ItemWrapper{
        font-family: 'Noto Sans KR', sans-serif;
        margin: 30px auto;
        width: 88vw;
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        border: solid white;
        justify-content: space-between;
  
      }


    }