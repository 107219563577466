.sch_board{
    width: 100%;
    height: 100vh;
    background-color: #F3EED9;

    display: flex;
    justify-content: center;
    align-items: center;

}

.text{
    display: inline;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 300;
    font-size: 3vw;
    color: #57423F;
}
