
.promenu_board{
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    margin: 0 auto;

    /* 너비는 소개파트 너비에 맞춰서 */
    width:1024px;
    flex-wrap: wrap;


}

.partTitle{
    /* font-family: 'Noto Sans KR', sans-serif; */
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    margin-top: 10vh;
    margin-bottom: 3vh;
}


.title{
    width: 49%;
    background-color: #B43131;
    height: 250px;
    margin-bottom: 2%;
    border-radius: 20px;

    color: #F3EED9;
    font-size: 60px;
    font-family: 'Noto Sans KR', sans-serif;
    text-align: center;
    line-height: 250px;
    
}

.explanation{
    width:49%;
    background-color:#F3EED9;
    height: 250px;
    margin-bottom: 2%;
    border-radius: 22px;

    color: #57423F;
    font-size: 20px;
    font-family: 'Noto Sans KR', sans-serif;

}

.script{
    display: flex;
    width : 85%;
    height: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
    line-height: 130%;

}


@media (max-width: 700px) {
.promenu_board{
    width: 80vw;
    flex-direction: column;
    justify-content: center;
}

.title{
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    margin-bottom: 0;
    border-radius:30px 30px 0px 0px ;

    font-size: 10vw;
}

.explanation{
    width: 100%;
    border-radius:0px 0px 30px 30px;
    margin-bottom: 3vh;
    font-size: 5.2vw;
    height: 45vh;
}


}


