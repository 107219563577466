@media(min-width: 1024px) {
    .introduction_title_box{
        padding-bottom: 47px;
    }

    .introduction_list_title_box {
            line-height: 48px;
    }

    .introduction_list_title {
            align-items: center;
    }

    .introduction_list_title_num{
            margin-right: 180px;
    }

    .introduction_explanation_text_box {
           padding-left: 220px;
           font-size: 19px;
    }

}

@media(max-width: 1024px) {
    .introduction_background {
        flex-wrap:wrap;
    }

    .introduction_title_box{
        padding: 0 1rem 1rem
    }

    .introduction_list_title_box {
            height: 84px
    }

    .introduction_list_title {
            height: 42px;
            line-height: 42px;
            flex-direction: column;
            align-items: flex-start;
    }

    .introduction_list_title_num{
            margin: 0;
            padding: 0 20px;

    }

    .introduction_explanation_text_box {
            padding: 0 20px;
            font-size: 16px;
    }

}


@media(max-width: 480px) {
    .introduction_background {
        padding:36px 0 20px
    }

    .introduction_title_text {
        /*font-size: 22px;*/
        font-size: 48px;
    }

    .introduction_list_title_num {
        font-size: 16px
    }

    .introduction_list_title_text * {
        font-size: 14px
    }

    .introduction_explanation_text_box * {
        margin-top: 15px;
        font-size: 14px;
        line-height: 21.6px
    }
}


ul {
    list-style: none;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

}

li {
    text-align: -webkit-match-parent;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}


div {
    display:block;
}


.introduction_background {
    height: 650px;
    padding: 110px 0;
    background:url('') fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.introduction_list_box {
    margin: 0;
    padding: 0;
}



.introduction_title_box{
    max-width: 1024px;
    margin: 0 auto;
}

.introduction_title_text{
    margin: 0;
    padding: 0;
    display: block;
    margin-block-start: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 600;
    font-size: 48px;;
    text-align: center;
}

.introduction_list_title_box {

}

.introduction_list_title {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
}

.introduction_list_title_num {
    margin-right: 180px;
    list-style: none;
    font-size: 24px;
    font-family: 'Noto Sans KR', sans-serif;
}

.introduction_list_title_text {
    margin: 0;
    font-size: 20px;
    /* background-image: linear-gradient(135deg, #B43131,   #FF8C00); */
    background-image: linear-gradient(135deg, #B43131, #e9daad);
    color: #fff;
    width: 100%;
    padding: 0 20px;
    font-family: 'Noto Sans KR', sans-serif;

}
{/* #FFA500 (주황색) / #FF8C00 (짙은 주황색) / #FF4500 (주황빛 붉은색)  */}

.introduction_explanation_text_box {
    max-width: 1024px;
    margin: 0 auto;
    line-height: 40px;
    font-family: 'Noto Sans KR', sans-serif;
    height: 180px;
}

.english_text{
    font-family: 'Tektur', cursive;
}


