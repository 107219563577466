.flip { 
    width: 200px;
    height: 250px;
    perspective: 1100px;
  }
  
  .card {
    width: 100%; 
    height: 100%; 
    position: relative;
    transition: .4s;
    transform-style: preserve-3d;
  }	
  
  .front, .back {
    position: absolute;
    width: 100%; 
    height: 100%;
    backface-visibility: hidden;
  }
  
  .front {
    background: red; 
  }
  
  /* // 뒷면은 사전에 미리 뒤집기 */
  .back { 
    background: royalblue; 
    transform: rotateY(180deg);
  }
  
  /* // 호버 시 뒤집기 */
  .flip:hover .card {
    transform: rotateY(180deg);
  }