.slide_border{
  border-radius: 5px;
  padding: 5px;
  margin: 200px 0 200px 0;
  /*box-shadow: 0 0 5px #ddd;*/
  text-align: center;
}

.slide_image{
  margin: 10px auto 30px auto;
  /* width: 90%; */
  width: 65vw;
  /* height: 90%; */
  height: 80vh;
  /* border-radius: 30px; */
  /* border-radius: 100px; */
  /*box-shadow: 0 0 5px #ddd;*/
}

.slide_title{
  margin: 20px 0 20px 0;
  font-size: 44px;
  font-weight: 600;
}

@media (max-width: 700px) {
  .slide_image{
    margin: 1vh auto 1vh auto;
    /* width: 90%; */
    width: 90vw;
    /* height: 90%; */
    height: 35vh;
    /* border-radius: 30px; */
    /* border-radius: 100px; */
    /*box-shadow: 0 0 5px #ddd;*/
  }

  .slide_title{
    margin: 20px 0 20px 0;
    font-size: 9vw;
    font-weight: 600;
  }


 }