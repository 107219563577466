.footer{

    width: 100%;
    color: white;
    background-color:black;
}

.footerText{
    /*display:flex;*/
    padding: 10px;
}

.footerTextIn{
    margin: 30px;
}

.footerHits{
    margin: 30px;

}
.footerImage{
    width: 20px;
    height: 20px;
}

@media(max-width: 480px) {
.footerTextIn{
    margin: 30px;
    display:none;
}
    .footerText{
    /*display:flex;*/
    padding: 10px;
}

.footerTextIn{
    margin: 30px;
}

.footerHits{
    display: none;
    margin: 30px;

}
.footerImage{
    width: 20px;
    height: 20px;
}

#kuhasCopyright{
    display:block;
    font-size:14px;
}

}